import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

let flowApi, translation;

export const getViewerEssentials = () => ({
  flowAPI: flowApi,
  t: translation,
});

export const setViewerEssentials = (flowAPI: PlatformControllerFlowAPI) => {
  flowApi = flowAPI;
  translation = flowAPI.translations.t;
};
