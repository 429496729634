import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { bi } from '.';
import { WidgetPropsI } from '../types';

export const biOnPropsChange = (
  flowAPI: PlatformControllerFlowAPI,
  changedData: Partial<WidgetPropsI>,
) => {
  Object.entries(changedData).forEach(([prop, newValue]) => {
    switch (prop) {
      case 'accountId':
        const newStatus = newValue ? 'connected' : 'disconnected';
        bi({ flowAPI }).statusChanged(newStatus);
        break;
      default:
        return;
    }
  });
};
