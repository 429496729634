import { gridPropsOverride } from '../../constants';
import { WidgetPropsI } from '../../types';

export const overrideWithMobileProps = (
  props: WidgetPropsI,
  isMobile: boolean,
  isGrid: boolean,
): WidgetPropsI => {
  const {
    showLoadMore_m,
    mediasOnInitialLoad_m,
    mediasOnLoadMore_m,
    shouldLimitImages_m,
    ...restOfProps
  } = props;
  const updatedProps = { ...restOfProps } as WidgetPropsI;

  if (isMobile) {
    updatedProps.mediasOnInitialLoad = mediasOnInitialLoad_m;
    updatedProps.showLoadMore = showLoadMore_m;
    updatedProps.shouldLimitImages = isGrid ? true : shouldLimitImages_m;
    updatedProps.mediasOnLoadMore = mediasOnLoadMore_m;
  }

  return updatedProps;
};

export const getGridOverride = (isGrid: boolean) => {
  return isGrid ? gridPropsOverride : {};
};
