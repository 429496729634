import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ListInstagramAccountMediaResponse } from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import { igApi, PaginationService } from '../../api';
import { convertMediasToPG } from './convertMediasToPG';
import { WidgetPropsI } from '../../types';

let userMediasAccount: Record<string, ListInstagramAccountMediaResponse> = {};

export const fetchConnectedUserData = async (
  flowAPI: PlatformControllerFlowAPI,
  props: WidgetPropsI,
) => {
  const { getMedias, getAccount } = igApi(flowAPI);
  const { isViewer, isPreview } = flowAPI.environment;
  const isViewMode = isViewer || isPreview;
  // Create a new Pagination Service Instance
  const paginationInstance = new PaginationService();

  const {
    accountId,
    mediasOnInitialLoad,
    showLoadMore,
    mediasOnLoadMore,
    shouldLimitImages,
  } = props;

  const fetchOnLoad = showLoadMore ? mediasOnLoadMore : 0;
  const limit = shouldLimitImages ? mediasOnInitialLoad + fetchOnLoad : null;
  const igUser = await getAccount(accountId);

  const userMediasStore = await getMedias(accountId, {
    isViewMode, // For widget not to fetch further data in editor on prop changes via panel
    limit,
    paginationInstance,
  });

  userMediasAccount = { ...userMediasAccount, [accountId]: userMediasStore };

  const userMedias = {
    ...userMediasStore,
    media: shouldLimitImages
      ? userMediasStore.media?.slice(0, mediasOnInitialLoad)
      : userMediasStore.media,
  };

  const addedMedias = convertMediasToPG(userMedias);
  const allLightboxMedias = convertMediasToPG(userMediasStore);

  const initialLightboxData = {
    user: igUser,
    media: allLightboxMedias,
    props,
    userMedias,
    paginationInstance,
    userMediasStore,
  };

  // User without posts - throw error
  if (allLightboxMedias?.length === 0) {
    throw {
      message: 'noPostsYet',
    };
  }

  return {
    igUser,
    addedMedias,
    initialLightboxData,
  };
};
