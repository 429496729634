import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetInstagramAccountResponse } from '@wix/ambassador-instagram-feed-v1-instagram-account/types';
import { DEMO_MEDIA, IG_URL, MP_URL, PARASTORAGE_URL } from '../../constants';
import { PgItemType } from './convertMediasToPG';
import { DemoDataInfoI, WidgetPropsI } from '../../types';
import { getStorage } from './storageUtils';

export const generateTemplate = async (
  flowAPI: PlatformControllerFlowAPI,
  props: WidgetPropsI,
): Promise<{
  templateMedias: PgItemType[];
  templateUser: GetInstagramAccountResponse;
}> => {
  const { t } = flowAPI.translations;
  const { templatesData, isClassic: isClsProps } = props;
  const { isClassic: isClassicStorage } = getStorage(flowAPI);
  const isClassic = isClsProps || isClassicStorage;
  let countMedias, folderName, description, userName, videos, assetsUrl;
  if (props.templatesData) {
    try {
      const parsedData = JSON.parse(templatesData);
      assetsUrl = MP_URL;
      description = t('instagram-blocks.widget.demo.templates.caption');
      ({ countMedias, folderName, userName } = parsedData);
    } catch (error) {
      console.log('Could not generate template, generate demo', error);
      setDataForDemo(isClassic);
    }
  } else {
    setDataForDemo(isClassic);
  }

  const dataToConvert = {
    assetsUrl,
    description,
    folderName,
    countMedias,
    userName,
    videos,
  };

  function setDataForDemo(isClsc: boolean) {
    const editorType = isClsc ? DEMO_MEDIA.classic : DEMO_MEDIA.studio;
    ({ folderName, userName, countMedias, videos } = editorType);
    assetsUrl = PARASTORAGE_URL;
    description = t(editorType.caption);
  }

  return setUserAndMedias(dataToConvert);
};

const setUserAndMedias = (
  dataInfo: DemoDataInfoI,
): {
  templateMedias: PgItemType[];
  templateUser: GetInstagramAccountResponse;
} => {
  const { assetsUrl, description, folderName, countMedias, userName, videos } =
    dataInfo;

  const templateMedias = Array.from({ length: countMedias }, (_, index) => ({
    src: `${assetsUrl}${folderName}/${String(index + 1).padStart(2, '0')}.jpg`,
    type: 'image',
    description,
    alt: description,
    link: IG_URL + userName,
    timestamp: new Date(),
  })) as PgItemType[];

  if (videos?.length) {
    videos.forEach((videoSlot, index) => {
      const videoPg: PgItemType = {
        type: 'video',
        description,
        alt: description,
        src: `${assetsUrl}${folderName}/video_src${String(index + 1).padStart(
          2,
          '0',
        )}.mp4`,
        thumbnail: `${assetsUrl}${folderName}/video_thumb${String(
          index + 1,
        ).padStart(2, '0')}.jpg`,
        link: IG_URL + userName,
        timestamp: new Date(),
      };
      templateMedias.splice(videoSlot - 1, 0, videoPg) as PgItemType[];
    });
  }

  const templateUser: GetInstagramAccountResponse = {
    instagramAccount: {
      id: '',
      revision: '1',
      instagramInfo: {
        instagramId: userName,
        instagramUsername: userName,
      },
    },
  };

  return { templateMedias, templateUser };
};
