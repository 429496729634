export class PaginationService {
  private cursor: string | null = null;
  private hasMoreMedias: boolean = true;
  private isLoading: boolean = false;

  constructor() {
    this.cursor = null;
  }

  setCursor({ next, hasNext }) {
    this.setIsLoading(false);
    this.cursor = next;
    this.hasMoreMedias = hasNext;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  getCursor() {
    return this.cursor;
  }

  getHasMoreMedias() {
    return this.hasMoreMedias;
  }
}
