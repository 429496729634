export const createExpandModeAriaLabel = (userName, timestamp) => {
  const date = tsToDate(timestamp);
  return `Photo by ${userName} ${date}`;
};

const tsToDate = (timestamp: string): string => {
  const dateObject = new Date(timestamp);
  const formattedDate = `${dateObject.getFullYear()}${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}${dateObject.getDate().toString().padStart(2, '0')}`;

  return formattedDate;
};
