export const DEMO_MEDIA = {
  classic: {
    caption: 'instagram-blocks.widget.demo.classic.caption',
    folderName: 'New-IG',
    countMedias: 18,
    userName: 'wix',
    videos: [],
  },
  studio: {
    caption: 'instagram-blocks.widget.demo.studio.caption',
    countMedias: 12,
    folderName: 'New-IG-Studio',
    userName: 'wixstudio',
    videos: [3, 8],
  },
};
