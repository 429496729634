import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import cloneDeep from 'lodash/cloneDeep';
import { CLICK_ACTION, ElementRoles, PgCustomOnclick } from '../../enums';
import { selectorFactory } from './selectorFactory';
import { igApi } from '../../api';
import { FULL_WIDGET_CONTROLLER_ID } from '../../constants';
import { convertMediasToPG, PgItemType } from './convertMediasToPG';
import { LightboxDataType, WidgetPropsI } from '../../types';
import { onLoadMoreDemoHandler, onLoadMoreHandler } from './onLoadMoreHandler';

type ListenerArgs = [
  pgWidgetType: any,
  flowAPI: PlatformControllerFlowAPI,
  $w: any,
  props: WidgetPropsI,
  lightboxData: LightboxDataType,
];

export const addEventListeners = (
  flowAPI: PlatformControllerFlowAPI,
  $w,
  props: WidgetPropsI,
  addedLightboxData: LightboxDataType,
) => {
  const { name } = flowAPI.controllerConfig;
  const { accountId, shouldLimitImages, showLoadMore } = props;
  const isMainWidget = FULL_WIDGET_CONTROLLER_ID === name;
  const proGalleryWidget = isMainWidget
    ? $w(`#${ElementRoles.ProGallery}`)
    : $w(`#${ElementRoles.ExpandProGallery}`);

  const args = [
    proGalleryWidget,
    flowAPI,
    $w,
    props,
    addedLightboxData,
  ] as ListenerArgs;

  // On click item
  onItemClickedListener(...args);
  // Get more items (scroll)
  if (isMainWidget && !shouldLimitImages && !showLoadMore && accountId) {
    onGetMoreItemsListener(...args);
  }
  // on loadMore click
  if (showLoadMore) {
    onClickLoadMore(...args);
  }
};

const onItemClickedListener = (...args: ListenerArgs) => {
  const [pgWidget, _, $w, props, lightboxData] = args;
  const { onClickAction, accountId } = props;
  pgWidget.onItemClicked((pgItem: PgItemType) => {
    if (onClickAction === PgCustomOnclick.OpenExpand) {
      const { openExpandMode } = selectorFactory($w);
      const lbData = lightboxData[accountId] ?? lightboxData;
      openExpandMode({ pgItem, data: lbData });
    }
  });
};

const onClickLoadMore = (...args: ListenerArgs) => {
  const [_, flowAPI, $w, props, lightboxData] = args;
  $w(`#${ElementRoles.LoadMore}`).onClick(async () => {
    const lbData = lightboxData[props.accountId] ?? lightboxData;
    props.accountId
      ? onLoadMoreHandler($w, flowAPI, lbData, props)
      : onLoadMoreDemoHandler($w, lbData, props);
  });
};

const onGetMoreItemsListener = (...args: ListenerArgs) => {
  const [pgWidget, flowAPI, _, props, lightboxData] = args;
  const lbData = lightboxData[props.accountId] ?? lightboxData;
  const { paginationInstance } = lbData;
  pgWidget.onGetMoreItems(async () => {
    const mediasApi = await igApi(flowAPI).getMedias(props.accountId, {
      paginationInstance,
    });
    const pgMediasApi = convertMediasToPG(mediasApi);
    pgWidget.addItems(pgMediasApi);
    lbData.media = [...lbData.media, ...pgMediasApi];
  });
};
